import { colors } from '@material-ui/core'

const INITIATED_STATUS = 'initiated'
const MEETING_STATUS = 'meeting'
const CONVERTED_STATUS = 'converted'
const CHURN_STATUS = 'churn'

const CHURN_STEPPER_SOLICITATION_ID_FORM = 'solicitation-churn-form'
const CHURN_STEPPER_MEETING_ID_FORM = 'meeting-churn-form'
const CHURN_STEPPER_RESULT_ID_FORM = 'result-churn-form'

const STATUSES_INITIAL = [INITIATED_STATUS, MEETING_STATUS]

const STATUS_LABEL_TRANSLATION = {
  [INITIATED_STATUS]: 'Iniciado',
  [MEETING_STATUS]: 'Reunião agendada',
  [CONVERTED_STATUS]: 'Convertido',
  [CHURN_STATUS]: 'Churn',
}

const CHIP_STATUS_COLOR = {
  [INITIATED_STATUS]: colors.orange[800],
  [MEETING_STATUS]: colors.blue[800],
  [CONVERTED_STATUS]: colors.green[800],
  [CHURN_STATUS]: colors.red[800],
}

const SOLICITATION_FORM_STEPS = [
  'Solicitação',
  'Reunião',
  'Resultado',
  'Cobranças',
]

const COMPANY_INFO_CONTROL_ITEMS = [
  {
    component: 'textfield',
    label: 'ID do Ticket',
    controlName: 'ticketId',
    inputMode: 'numeric',
    gridMd: 3,
  },
  {
    component: 'datePicker',
    label: 'Data da solicitação',
    controlName: 'ticketDate',
    inputMode: 'numeric',
    gridMd: 3,
  },
  {
    component: 'textfield',
    label: 'Empresa',
    controlName: 'company',
    inputMode: 'text',
    gridMd: 6,
  },
  {
    component: 'textfield',
    label: 'Segmento',
    controlName: 'segment',
    inputMode: 'text',
    gridMd: 6,
  },
  {
    component: 'textfield',
    label: 'Documento',
    controlName: 'document',
    inputMode: 'numeric',
    gridMd: 3,
  },
  {
    component: 'datePicker',
    label: 'Início do contrato',
    controlName: 'contractDate',
    inputMode: 'numeric',
    gridMd: 3,
  },
  {
    component: 'textfield',
    label: 'Porte',
    controlName: 'companySize',
    inputMode: 'text',
    gridMd: 6,
  },
  {
    component: 'textfield',
    label: 'MRR',
    controlName: 'mrr',
    inputMode: 'decimal',
    gridMd: 6,
  },
  {
    component: 'textfield',
    label: 'Vendedor',
    controlName: 'seller',
    inputMode: 'text',
    gridMd: 6,
  },
  {
    component: 'textfield',
    label: 'Colaborador responsável pelo processo de churn',
    controlName: 'responsible',
    inputMode: 'text',
    gridMd: 6,
  },
]

const MEETING_CONTROL_ITEMS = [
  {
    label: 'E-mail do responsável',
    controlName: 'email',
    inputMode: 'email',
    gridMd: 5,
  },
  {
    label: 'Com quem será a reunião',
    controlName: 'name',
    inputMode: 'text',
    gridMd: 5,
  },
]

const DISCOUNT_BENEFITS_FROM_RESULTS_CONTROL_ITEMS = [
  {
    label: 'Valor atual',
    controlName: 'currentValue',
  },
  {
    label: 'Valor com desconto',
    controlName: 'discount',
  },
]

const REASON_CHURN_ITEMS = [
  { id: 'Gratuidade', name: 'Gratuidade' },
  { id: 'Falta de funcionalidade', name: 'Falta de funcionalidade' },
  { id: 'Troca de representante', name: 'Troca de representante' },
  { id: 'Não alcançou sucesso', name: 'Não alcançou sucesso' },
  { id: 'Bad Fit', name: 'Bad Fit' },
  { id: 'Encerramento de empresa', name: 'Encerramento de empresa' },
  { id: 'Clientes inadimplentes', name: 'Clientes inadimplentes' },
  { id: 'Parceria', name: 'Parceria' },
  {
    id: 'Dificuldade financeira da empresa',
    name: 'Dificuldade financeira da empresa',
  },
  {
    id: 'Não especificado pelo cliente',
    name: 'Não especificado pelo cliente',
  },
  {
    id: 'Perda para prestador de serviço',
    name: 'Perda para prestador de serviço',
  },
]

const QUANTITY_MONTHS_ITEMS = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
]

const DEFINITION_CHURN_STEPPER_FORM = {
  0: CHURN_STEPPER_SOLICITATION_ID_FORM,
  1: CHURN_STEPPER_MEETING_ID_FORM,
  2: CHURN_STEPPER_RESULT_ID_FORM,
}

const churn = {
  INITIATED_STATUS,
  MEETING_STATUS,
  CONVERTED_STATUS,
  CHURN_STATUS,
  CHURN_STEPPER_SOLICITATION_ID_FORM,
  CHURN_STEPPER_MEETING_ID_FORM,
  CHURN_STEPPER_RESULT_ID_FORM,
  STATUSES_INITIAL,
  STATUS_LABEL_TRANSLATION,
  CHIP_STATUS_COLOR,
  SOLICITATION_FORM_STEPS,
  COMPANY_INFO_CONTROL_ITEMS,
  MEETING_CONTROL_ITEMS,
  DISCOUNT_BENEFITS_FROM_RESULTS_CONTROL_ITEMS,
  REASON_CHURN_ITEMS,
  QUANTITY_MONTHS_ITEMS,
  DEFINITION_CHURN_STEPPER_FORM,
}

export default churn
