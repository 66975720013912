import { useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Box } from '@material-ui/core'
import {
  Description as DescriptionIcon,
  PaymentSharp as PaymentSharpIcon,
} from '@material-ui/icons'

import { BaseDialog, LoadingFeedback } from 'components'
import { Information, Form } from './components'

import useStyles from './styles'

const CreateOrder = ({
  paymentContract,
  refresh = () => {},
  openNewOrder = false,
  setOpenNewOrder = () => {},
}) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => setValue(newValue)

  return (
    <BaseDialog
      open={openNewOrder}
      title="NOVA COBRANÇA"
      actionButtonText="Gerar cobrança"
      closeButtonText="Cancelar"
      setOpen={setOpenNewOrder}
      formRef="id-new-payment"
      dialogSize="lg"
    >
      <LoadingFeedback open={isLoading} />
      <Box mb={5}>
        <Tabs variant="standard" value={value} onChange={handleChange}>
          <Tab
            className={classes.customTab}
            icon={<PaymentSharpIcon />}
            iconPosition="start"
            label="Formulário"
            value={0}
          />
          <Tab
            className={classes.customTab}
            icon={<DescriptionIcon />}
            iconPosition="start"
            label="Informações"
            value={1}
          />
        </Tabs>
      </Box>
      <Box hidden={value === 1}>
        <Form
          paymentContractId={paymentContract?.id}
          refresh={refresh}
          setIsLoading={setIsLoading}
          setOpenNewOrder={setOpenNewOrder}
          methodPayment={paymentContract?.methodPayment}
        />
      </Box>
      <Box hidden={value === 0}>
        <Information paymentContract={paymentContract} />
      </Box>
    </BaseDialog>
  )
}

CreateOrder.propTypes = {
  preRegistrationToken: PropTypes.string,
  refresh: PropTypes.func,
  openNewOrder: PropTypes.bool,
  setOpenNewOrder: PropTypes.func,
  setIsLoading: PropTypes.func,
}

export default CreateOrder
