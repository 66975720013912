import helpers from 'helpers'
import constants from 'constants/index'

const formatSupplierParamsToApi = (params) => {
  if (!params) return {}

  const { isAddress } = params

  let formattedParams = {
    name: params.name,
    description: params.description,
    cnpj: helpers.formatters.onlyNumbersString(params.cnpj),
    cpf: helpers.formatters.onlyNumbersString(params.cpf),
    siteUrl: params.siteUrl || null,
    statusId: params.statusId,
    phone: helpers.formatters.onlyNumbersString(params.phone),
    email: params.email,
    kind: constants.companies.KINDS.SUPPLIER,
    groupId: helpers.formatters.getIdFromObject(params.groupId),
    subgroupId: helpers.formatters.getIdFromObject(params.subgroupId),
    segmentId: helpers.formatters.getIdFromObject(params.segmentId),
    companySizeId: helpers.formatters.getIdFromObject(params.companySizeId),
    ...(isAddress && {
      address: {
        street: params.street,
        number: params.number,
        neighborhood: params.neighborhood,
        complement: params.complement || null,
        postalCode: helpers.formatters.onlyNumbersString(params.postalCode),
        city: params.city,
        country: params.country,
        state: params.state,
      },
    }),
    ...(params.companyTemplateId && {
      companyTemplateId: helpers.formatters.getIdFromObject(
        params.companyTemplateId,
      ),
    }),
  }

  return formattedParams
}

export default formatSupplierParamsToApi
