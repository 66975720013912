import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
}))

export default styles
