import PropTypes from 'prop-types'

import { Box, Grid, Typography } from '@material-ui/core'
import { Card, Label, Permitted } from 'components'
import constants from 'constants/index'
import helpers from 'helpers'
import { DemoPartnerMenuItems } from './components'

const DemoPartner = ({ refresh, demoPartner, companyId }) => {
  return (
    <Card
      title="Dados de gratuidade"
      actionButton={
        <Permitted tag={constants.permissions.COMPANIES.UPDATE_DEMO_PARTNER}>
          <DemoPartnerMenuItems
            refresh={refresh}
            editionMode={true}
            demoPartner={demoPartner}
            companyId={companyId}
          />
        </Permitted>
      }
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Label titleVariant="body1" title="Tipo">
              <Typography variant="body2">
                {constants.preRegistration.KIND_TRIAL[demoPartner?.kind] ||
                  'Não definido'}
              </Typography>
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label titleVariant="body1" title="Vencimento">
              <Typography variant="body2">
                {helpers.formatters.date(demoPartner?.endDate) ||
                  'Sem vencimento'}
              </Typography>
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

DemoPartner.propTypes = {
  demoPartner: PropTypes.object,
}

export default DemoPartner
