const ACTIVE_STATUS = 'ACTIVE'
const PENDING_STATUS = 'PENDING'
const REFUSED_STATUS = 'REFUSED'
const CANCELED_STATUS = 'CANCELED'

const STATUSES = {
  ACTIVE_STATUS,
  PENDING_STATUS,
  REFUSED_STATUS,
  CANCELED_STATUS,
}

const RESPONSIBLE = 'responsible'
const OBSERVER = 'observer'
const FANTASY = 'fantasy'

const DPO_TYPE = {
  RESPONSIBLE,
  OBSERVER,
  FANTASY,
}

const TAB_NEW = 'newInviteAdvisor'
const TAB_DISPLAY = 'displayInviteAdvisor'

const inviteAdvisor = {
  STATUSES,
  TAB_NEW,
  TAB_DISPLAY,
  DPO_TYPE,
}

export default inviteAdvisor
