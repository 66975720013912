import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'

import { AddInviteAdvisor } from '..'
import { LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'

const DialogDpoFantasy = ({
  refresh,
  open,
  setOpen,
  invite,
  company,
  inviteTypeText,
  isEdit,
  setAdvisorData,
}) => {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [buttonClick, setButtonClick] = useState(false)
  const [dpoIsFantasy, setDpoIsFantasy] = useState(invite?.fantasyDpo)

  const snackbar = useSnackbar()

  const handleCancel = () => {
    setOpen(false)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      if (!isEdit) {
        setAdvisorData(data)
      } else {
        const newData = {
          fantasyDpo: data?.email,
          email: company?.outsourced?.email,
        }

        await service.dponet.outsourcedDpo.update({
          data: newData,
          outsourcedId: company?.outsourced?.id,
        })

        refresh()
      }

      setOpen(false)
      snackbar.open({
        message: `${inviteTypeText} vinculado à sua empresa!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const handleClickSubmitButton = () => {
    setButtonClick(true)
  }

  const handleDestroy = async () => {
    setLoading(true)
    try {
      const newData = { fantasyDpo: null }

      await service.dponet.outsourcedDpo.update({
        data: newData,
        outsourcedId: company?.outsourced?.id,
      })

      setOpen(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const inviteFormatted =
    typeof invite === 'string'
      ? { email: invite }
      : invite?.email
      ? invite
      : { email: '' }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="confirm-change"
      maxWidth="md"
      fullWidth
    >
      <LoadingFeedback open={loading} />
      <DialogTitle>
        <Box>
          <Typography variant="h4">{inviteTypeText}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <Typography variant="h5">Adicionar DPO Fantasia</Typography>
        </Box>
        <AddInviteAdvisor
          disabled={disabled}
          setDisabled={setDisabled}
          onSubmit={onSubmit}
          invite={inviteFormatted}
          buttonClick={buttonClick}
          setButtonClick={setButtonClick}
          checked={dpoIsFantasy}
          setChecked={setDpoIsFantasy}
          isFantasy
        />
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" my={2} mr={2}>
        <Button
          variant="outlined"
          type="button"
          onClick={handleCancel}
          disableElevation
        >
          CANCELAR
        </Button>
        {invite && (
          <Box pl={3}>
            <Button
              variant="outlined"
              disableElevation
              disabled={loading}
              onClick={handleDestroy}
            >
              CANCELAR VÍNCULO
            </Button>
          </Box>
        )}
        {!disabled && (
          <Box pl={3}>
            <Button
              startIcon={<CheckIcon />}
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleClickSubmitButton}
              disabled={loading}
            >
              Confirmar
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

DialogDpoFantasy.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  invite: PropTypes.object,
  isDpoInvite: PropTypes.bool,
  inviteTypeText: PropTypes.string,
  refresh: PropTypes.func,
  company: PropTypes.object,
  isEdit: PropTypes.bool,
}

DialogDpoFantasy.defaultProps = {
  open: false,
  setOpen: () => {},
  invite: {},
  isDpoInvite: false,
  inviteTypeText: '',
  refresh: () => {},
  company: {},
  isEdit: false,
}

export default DialogDpoFantasy
