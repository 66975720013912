import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { isNil } from 'lodash'

import { DatePicker } from '@material-ui/pickers'
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@material-ui/core'

import { Card, ConfirmationDialog } from 'components'

import PropTypes from 'prop-types'
import helpers from 'helpers'
import * as service from 'service'

import { useSnackbar } from 'hooks'

import schema from './schema'
import constants from 'constants/index'
import theme from 'theme'

const MeetingStep = ({
  churn,
  setLoading,
  handleNextStep,
  handleBackStep,
  handleBackPage,
  show,
}) => {
  const [noContact, setNoContact] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const snackbar = useSnackbar()

  const currentDate = new Date()
  const churnMeeting = churn?.churnMeeting

  const { control, errors, handleSubmit, triggerValidation, getValues } =
    useForm({
      validationSchema: schema,
      defaultValues: {
        date: churnMeeting?.date || null,
        email: churnMeeting?.email || '',
        name: churnMeeting?.name || '',
        observation: churnMeeting?.observation || '',
      },
    })

  const handleChangeSwitch = (event) => setNoContact(event?.target?.checked)
  const handleOpenDialog = () => setOpenDialog((prevOpen) => !prevOpen)

  const handleValidationForm = async () => {
    const isValid = await triggerValidation()

    if (!isValid && !show) {
      return
    } else if (churn?.status === constants.churn.INITIATED_STATUS && !show) {
      handleOpenDialog()
    } else {
      handleNextStep()
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)

    let date = churnMeeting?.date || data?.date?.format('DD/MM/YYYY')

    try {
      await service.dponet.churns.meeting({
        churnId: churn.id,
        churn: {
          name: data?.name,
          email: data.email,
          observation: data?.observation,
          date: date,
        },
      })

      snackbar.open({
        message: `Reunião realizada com sucesso!`,
        variant: 'success',
      })

      if (date > moment(currentDate).format('DD/MM/YYYY')) {
        handleBackPage()
      } else {
        handleNextStep()
      }
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro ao tentar realizar a reunião',
        variant: 'error',
      })
    } finally {
      setLoading(false)
      handleOpenDialog()
    }
  }

  return (
    <Box
      component="form"
      id={constants.churn.CHURN_STEPPER_MEETING_ID_FORM}
      onSubmit={handleSubmit(onSubmit)}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(4)}
    >
      <Card title="Agendamento da reunião" paddingCard={0}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Controller
              as={
                <DatePicker
                  label="Data da reunião"
                  format="DD/MM/yyyy"
                  error={!!errors?.date}
                  helperText={<>{errors?.date?.message}</>}
                  onChange={(newValue) => ({
                    value: newValue,
                  })}
                  fullWidth
                  disabled={churnMeeting || show}
                />
              }
              control={control}
              name="date"
              mode="onChange"
            />
          </Grid>
          {constants.churn.MEETING_CONTROL_ITEMS.map((item, index) => (
            <Grid key={index} item xs={12} md={item?.gridMd}>
              <Controller
                as={
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    label={item?.label}
                    error={!!errors?.[item?.controlName]}
                    helperText={<>{errors?.[item?.controlName]?.message}</>}
                    fullWidth
                    InputProps={{
                      inputMode: item?.mode,
                    }}
                    disabled={churnMeeting || show}
                  />
                }
                control={control}
                name={item?.controlName}
                mode="onChange"
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={noContact || !isNil(churnMeeting?.observation)}
                  onChange={handleChangeSwitch}
                  name="noContact"
                />
              }
              label="Não foi possível contatar o cliente para agendamento de reunião / cliente não quis participar da reunião."
            />
          </Grid>
          {(noContact || !isNil(churnMeeting?.observation)) && (
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    label="Descrição"
                    error={!!errors?.observation}
                    helperText={errors?.observation?.message}
                    fullWidth
                    multiline
                    disabled={churnMeeting || show}
                    rows={4}
                  />
                }
                control={control}
                name="observation"
                mode="onChange"
              />
            </Grid>
          )}
        </Grid>
      </Card>
      <Box display="flex" justifyContent="flex-end" gridGap={theme.spacing(2)}>
        {show && (
          <Button variant="contained" onClick={handleBackStep}>
            Voltar
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleValidationForm}
        >
          {churn?.status === constants.churn.INITIATED_STATUS && !show
            ? 'Salvar'
            : 'Avançar'}
        </Button>
      </Box>
      <ConfirmationDialog
        open={openDialog}
        setOpen={setOpenDialog}
        buttonText="Salvar"
        message="Tem certeza que deseja salvar este agendamento?"
        subMessage="A reunião só poderá ser salva após agendamento com o cliente."
        actionAcceptButton={() => handleSubmit(onSubmit(getValues()))}
      />
    </Box>
  )
}

MeetingStep.propTypes = {
  churn: PropTypes.object,
  setLoading: PropTypes.func,
  handleNextStep: PropTypes.func,
  handleBackStep: PropTypes.func,
  handleBackPage: PropTypes.func,
  show: PropTypes.bool,
}

export default MeetingStep
