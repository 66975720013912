import * as yup from 'yup'

export const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  cpf: yup.string(),
  cnpj: yup.string(),
  siteUrl: yup.string(),
  email: yup.string().email().required(),
  phone: yup.string(),
  groupId: yup.object().nullable(),
  companySizeId: yup.object().nullable(),
  subgroupId: yup.object().nullable(),
  segmentId: yup.object().nullable(),
  street: yup.string().when('isAddress', {
    is: true,
    then: yup.string().required(),
  }),
  number: yup.string().when('isAddress', {
    is: true,
    then: yup.string().required(),
  }),
  neighborhood: yup.string().when('isAddress', {
    is: true,
    then: yup.string().required(),
  }),
  complement: yup.string(),
  postalCode: yup.string().when('isAddress', {
    is: true,
    then: yup.string().required(),
  }),
  city: yup.string().when('isAddress', {
    is: true,
    then: yup.string().required(),
  }),
  country: yup.string().when('isAddress', {
    is: true,
    then: yup.string().required(),
  }),
  state: yup.string().when('isAddress', {
    is: true,
    then: yup.string().required(),
  }),
  selectExistentResponsible: yup.boolean(),
  statusId: yup.number().required(),
  responsibleName: yup.string().when('selectExistentResponsible', {
    is: false,
    then: yup.string().required(),
  }),
  responsibleEmail: yup.string().when('selectExistentResponsible', {
    is: false,
    then: yup.string().email().required(),
  }),
  responsiblePhone: yup.string().when('selectExistentResponsible', {
    is: false,
    then: yup.string(),
  }),
  responsibleUserId: yup.object().when('selectExistentResponsible', {
    is: true,
    then: yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required(),
    }),
  }),
})

export default schema
