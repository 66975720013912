import PropTypes from 'prop-types'

import {
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Tooltip,
} from '@material-ui/core'

import { X as CloseIcon } from 'react-feather'

import constants from 'constants/index'

import useStyles from './styles'

const BaseDialog = ({
  actionButton = () => {},
  actionButtonText = '',
  children,
  chipLabel = '',
  closeButtonText = '',
  closeIconButton = false,
  dialogSize = 'sm',
  disabledSubmit = false,
  formRef,
  noMargin = false,
  open,
  setOpen = () => {},
  title = '',
  withoutBottomDivider = false,
  justifyActions = 'flex-start',
  actionButtonType = 'submit',
}) => {
  const classes = useStyles()

  const newChipLabel = chipLabel === constants.lastUpdates.KINDS_LABELS.N

  const closeDialog = () => setOpen(false)

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth={dialogSize}
      fullWidth
      className={classes.dialog}
    >
      <DialogTitle classes={{ root: classes.muiDialogTitleOverride }}>
        {chipLabel ? (
          <Box className={classes.boxTitle}>
            <Box className={classes.boxTitleTwo}>
              <Chip
                className={newChipLabel ? classes.new : classes.improvement}
                label={chipLabel}
              />
              <Box>
                <Tooltip title={title} placement="top">
                  <Typography className={classes.title} variant="h4">
                    {title}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
            {closeIconButton && (
              <Button onClick={actionButton}>
                <CloseIcon />
              </Button>
            )}
          </Box>
        ) : (
          <Typography className={classes.title} variant="h4">
            {title}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <Box my={noMargin ? 0 : 1}>{children}</Box>
      </DialogContent>
      {!withoutBottomDivider && (
        <DialogActions p={2}>
          <Box
            className={classes.actionsContainer}
            justifyContent={justifyActions}
          >
            <Box>
              <Button type="button" variant="outlined" onClick={closeDialog}>
                {closeButtonText}
              </Button>
            </Box>
            {actionButtonText && (
              <Button
                variant="contained"
                type={actionButtonType}
                color="primary"
                onClick={actionButton}
                form={formRef}
                disabled={disabledSubmit}
              >
                {actionButtonText}
              </Button>
            )}
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
}

BaseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  closeButtonText: PropTypes.string,
  actionButtonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  actionButton: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  dialogSize: PropTypes.string,
  formRef: PropTypes.string,
  noMargin: PropTypes.bool,
  closeIconButton: PropTypes.bool,
  withoutBottomDivider: PropTypes.bool,
  chipLabel: PropTypes.string,
  disabledSubmit: PropTypes.bool,
  justifyActions: PropTypes.string,
}

export default BaseDialog
