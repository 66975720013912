import { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'

import { LoadingFeedback } from 'components'

import * as service from 'service'
import helpers from 'helpers'
import { useSnackbar } from 'hooks'

const SupplierDestroyDialog = ({ open, onClose, companySupplier, refresh }) => {
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const handleDestroy = async () => {
    try {
      setLoading(true)

      await service.dponet.companySuppliers.destroy({
        companySupplierId: companySupplier?.id,
      })

      onClose()
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao deletar vinculo de empresa com fornecedor!',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">
            Tem certeza de que deseja excluir este vínculo?
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <LoadingFeedback open={loading} />
        <Typography variant="h6">
          Deseja confirmar a exclusão do vínculo com o fornecedor?
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onClose}>
          Voltar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleDestroy}
        >
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SupplierDestroyDialog
