import { useState } from 'react'

import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { Button } from '@material-ui/core'

import constants from 'constants/index'

import {
  ConfirmationDialog,
  ExportQuestionnairePdf,
  Permitted,
} from 'components'
import {
  FormDeleteDialog,
  ExportDataProcessesDialog,
  ImportDataProcessesDialog,
  ExportRipdDialog,
} from '../'

import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'
import helpers from 'helpers'

import { routes } from 'Routes'

const ActionButtons = ({ company, refresh }) => {
  const match = useLocation()
  const isEasy = match?.pathname === routes.companies.easy

  const [openActivateDialog, setOpenActivateDialog] = useState(false)
  const [openInactivateDialog, setOpenInactivateDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openExportModal, setOpenExportModal] = useState(false)
  const [openImportDataProcesses, setOpenImportDataProcesses] = useState()
  const [openExportRipd, setOpenExportRipd] = useState(false)

  const snackbar = useSnackbar()

  const companyIsInactive =
    company?.statusId === constants.companies.STATUSES.INACTIVE

  const handleActiveOrInactive = async () => {
    try {
      await service.dponet.companies.changeStatus({ companyId: company?.id })

      snackbar.open({
        message: `Empresa ${
          companyIsInactive ? 'ativada' : 'inativada'
        } com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: `Falha ao ${
          companyIsInactive ? 'ativar' : 'inativar'
        } empresa.`,
        variant: 'error',
      })
    }
    setOpenActivateDialog(false)
    setOpenInactivateDialog(false)
    refresh()
  }

  return (
    <>
      <Permitted tag={constants.permissions.COMPANIES.INACTIVE_AND_ACTIVE}>
        {companyIsInactive ? (
          <Button
            fullWidth
            size="small"
            onClick={() => {
              setOpenActivateDialog(true)
            }}
          >
            Ativar
          </Button>
        ) : (
          <Button
            fullWidth
            size="small"
            onClick={() => {
              setOpenInactivateDialog(true)
            }}
          >
            Inativar
          </Button>
        )}
      </Permitted>
      <Permitted tag={constants.permissions.COMPANIES.DELETE}>
        <Button
          fullWidth
          size="small"
          onClick={() => {
            setOpenDeleteDialog(true)
          }}
        >
          Excluir
        </Button>
      </Permitted>
      {!isEasy && (
        <>
          <Permitted
            tag={constants.permissions.COMPANIES.EXPORT_DATA_PROCESSES}
          >
            <Button
              fullWidth
              size="small"
              onClick={() => {
                setOpenExportModal(true)
              }}
            >
              Exportar processos
            </Button>
          </Permitted>
          {!helpers.companies.isTemplate(company) && (
            <Permitted tag={constants.permissions.RIPD.EXPORT_ADMIN}>
              <Button
                fullWidth
                size="small"
                onClick={() => {
                  setOpenExportRipd(true)
                }}
              >
                Exportar RIPD
              </Button>
            </Permitted>
          )}
          <Permitted tag={constants.permissions.COMPANIES.CREATE}>
            <Button
              fullWidth
              size="small"
              onClick={() => {
                setOpenImportDataProcesses(true)
              }}
            >
              Importar processos
            </Button>
          </Permitted>

          {!!company?.name && (
            <ExportQuestionnairePdf company={company} fullWidth />
          )}
        </>
      )}

      <ConfirmationDialog
        open={openActivateDialog}
        setOpen={setOpenActivateDialog}
        message="Tem certeza que deseja ativar esta empresa?"
        cancelButtonText="Cancelar"
        buttonText="Ativar"
        actionAcceptButton={handleActiveOrInactive}
      />
      <ConfirmationDialog
        open={openInactivateDialog}
        setOpen={setOpenInactivateDialog}
        message="Tem certeza que deseja inativar esta empresa?"
        cancelButtonText="Cancelar"
        buttonText="Inativar"
        actionAcceptButton={handleActiveOrInactive}
      />
      <FormDeleteDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        companyId={company?.id}
        refresh={refresh}
      />
      <ExportDataProcessesDialog
        open={openExportModal}
        setOpen={setOpenExportModal}
        companyId={company?.id}
      />
      <ImportDataProcessesDialog
        open={openImportDataProcesses}
        setOpen={setOpenImportDataProcesses}
        companyId={company?.id}
      />
      <ExportRipdDialog
        open={openExportRipd}
        setOpen={setOpenExportRipd}
        companyId={company?.id}
      />
    </>
  )
}

ActionButtons.propTypes = {
  company: PropTypes.object,
  refresh: PropTypes.func,
}

ActionButtons.defaultProps = {
  refresh: () => {},
}

export default ActionButtons
