import { useState } from 'react'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Button, Grid, TextField, Box } from '@material-ui/core'

import { useCompany, useSnackbar } from 'hooks'
import { useCompaniesCache } from 'hooks/caches'

import { Card, LoadingFeedback, Select, ConfirmationDialog } from 'components'
import { JuridicOrPersonalForm, TemplateForm, SupplierForm } from './components'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

const CompanyForm = ({ editionMode, editionPermitted, companyData }) => {
  const [company, setCompany] = useState(companyData)
  const [loading, setLoading] = useState(false)
  const [loadingSupplier, setLoadingSupplier] = useState(false)
  const [isGenerate, setIsGenerate] = useState(false)

  const companiesCache = useCompaniesCache()
  const snackbar = useSnackbar()
  const location = useLocation()
  const { isLoading } = useCompany()

  const typeDefault = location?.state?.type

  const refresh = () => {
    companiesCache.useUpdateCache(company?.id)
  }

  const { control, watch } = useForm({
    defaultValues: {
      kind: company?.kind || typeDefault,
    },
  })

  const isKindPersonal = watch('kind') === constants.companies.KINDS.PERSONAL
  const isKindTemplate = watch('kind') === constants.companies.KINDS.TEMPLATE
  const isKindSupplier = company?.kind === constants.companies.KINDS.SUPPLIER

  const loadSupplierData = async (document, setValue) => {
    if (!editionMode) {
      try {
        setLoading(true)
        const response = await service.dponet.companies.verifyDocument(document)
        const supplierCompany = response?.data?.company

        if (!!supplierCompany?.id && company?.id !== supplierCompany.id) {
          setLoadingSupplier(true)
          setCompany({
            ...supplierCompany,
            statusId: constants.companies.STATUSES.ONBOARDING,
          })
          setLoadingSupplier(false)

          snackbar.open({
            message:
              'O documento informado foi identificado sendo pertencente de uma empresa fornecedora, portanto os dados da mesma foram carregados',
            variant: 'warning',
          })
        }
        setLoading(false)
      } catch {
        if (!!company?.id) {
          setLoadingSupplier(true)
          const field = isKindPersonal ? 'cpf' : 'cnpj'
          setValue(field, document)
          setCompany({
            [field]: helpers.formatters.documentFormatter(document),
          })
          setLoadingSupplier(false)
        }
        setLoading(false)
      }
    }
  }

  const handleUpgradeSupplier = async () => {
    setLoading(true)
    try {
      await service.dponet.companies.upgradeSupplierToClient(companyData?.id)

      snackbar.open({
        message: 'Empresa convertida em empresa real com sucesso',
        variant: 'success',
      })

      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao converter empresa em em real',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const generateClient = async () => {
    setLoading(true)
    try {
      await service.dponet.companies.generateClient(companyData?.id)

      snackbar.open({
        message: 'Sucesso! Cliente gerado com sucesso',
        variant: 'success',
      })

      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao gerar o cliente a partir da empresa',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setIsGenerate(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={isLoading || loading} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card
            title="Tipo da Empresa"
            actionButton={
              <Box display="flex">
                {isNil(companyData?.preRegistration) && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setIsGenerate(true)}
                  >
                    GERAR CLIENTE
                  </Button>
                )}
                <Box ml={2}>
                  {isKindSupplier && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleUpgradeSupplier}
                    >
                      CONVERTER EM REAL
                    </Button>
                  )}
                </Box>
              </Box>
            }
          >
            {!editionMode ? (
              <Controller
                control={control}
                name="kind"
                as={
                  <Select
                    label="Selecione o Tipo da Empresa"
                    items={constants.companies.SELECT_TYPE_COMPANIES_CREATE}
                  />
                }
              />
            ) : (
              <TextField
                fullWidth
                disabled
                defaultValue={helpers.companies.companyKindLabel(
                  company?.statusId,
                  company?.kind,
                )}
              />
            )}
          </Card>
          <Grid />
        </Grid>
        <Grid item xs={12}>
          {!isKindTemplate && !isKindSupplier && !loadingSupplier && (
            <JuridicOrPersonalForm
              company={company}
              setLoading={setLoading}
              disableCertFields={editionMode}
              editionPermitted={editionPermitted}
              refresh={refresh}
              loadSupplierData={loadSupplierData}
              typeDefault={typeDefault}
            />
          )}
          {isKindTemplate && (
            <TemplateForm
              company={company}
              setLoading={setLoading}
              disableCertFields={editionMode}
              editionPermitted={editionPermitted}
              refresh={refresh}
            />
          )}
          {isKindSupplier && (
            <SupplierForm
              company={company}
              setLoading={setLoading}
              disableCertFields={editionMode}
              editionPermitted={editionPermitted}
              refresh={refresh}
            />
          )}
        </Grid>
      </Grid>
      {isGenerate && (
        <ConfirmationDialog
          setOpen={setIsGenerate}
          open={isGenerate}
          message="Deseja gerar o cliente a partir da empresa?"
          subMessage="O cliente será gerado e após isso você poderá acessa-lo 
                      na lista de clientes, lembre-se de ir para o cliente e 
                      conferir se os dados estão corretos."
          actionAcceptButton={generateClient}
        />
      )}
    </>
  )
}

CompanyForm.propTypes = {
  editionMode: PropTypes.bool,
  editionPermitted: PropTypes.bool,
  companyData: PropTypes.object,
}

CompanyForm.defaultProps = {
  editionMode: false,
}

export default CompanyForm
