import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
  TablePagination,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { Permitted, ConfirmationDialog } from 'components'
import { PaymentOrdersTable, CreateOrderDialog } from './components'

import { usePagination, useFetch, useSnackbar } from 'hooks'

import constants from 'constants/index'
import * as service from 'service'

const PaymentOrdersCard = ({ paymentContract }) => {
  const [openNewOrder, setOpenNewOrder] = useState(false)
  const [openImportOrder, setOpenImportOrder] = useState(false)
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const { MANAGE_PAYMENT } = constants.permissions.PAYMENT_ORDERS

  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.paymentOrders.lists,
    {
      entityId: paymentContract?.id,
      entityType: 'PaymentContract',
      order: 'order_reference_date DESC, id DESC',
      perPage,
      page,
    },
    [page, perPage],
  )

  const handleImportOrder = async () => {
    try {
      setLoading(true)
      await service.dponet.paymentContract.importOrderOmie({
        paymentContractId: paymentContract?.id,
      })

      refresh()

      snackbar.open({
        message: 'Solicitação de importação de ordem executada com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.message ||
          'Ocorreu um erro ao solicitar a importação da ordens!',
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setOpenImportOrder(false)
    }
  }

  return (
    <Box mb={2}>
      <Card>
        <Box width="100%">
          <Grid container>
            <Grid item xs={6}>
              <Box
                width="100%"
                p={2}
                height="100%"
                display="flex"
                alignItems="center"
              >
                <Typography variant="h5">Ordens de pagamento</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Permitted tag={MANAGE_PAYMENT}>
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Box mr={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setOpenImportOrder(true)}
                    >
                      Importar cobranças (omie)
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenNewOrder(true)}
                  >
                    Nova Cobrança
                  </Button>
                </Box>
              </Permitted>
            </Grid>
          </Grid>
          <Divider />
        </Box>
        <PaymentOrdersTable
          paymentOrders={response?.data?.paymentOrders || []}
          refresh={refresh}
          isLoading={isLoading || loading}
        />
        <Box px={2} display="flex" justifyContent="flex-end">
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={isDesktop ? 'Por página' : ''}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Box>
      </Card>
      {openNewOrder && (
        <CreateOrderDialog
          paymentContract={paymentContract}
          refresh={refresh}
          openNewOrder={openNewOrder}
          setOpenNewOrder={setOpenNewOrder}
        />
      )}
      {openImportOrder && (
        <ConfirmationDialog
          open={openImportOrder}
          setOpen={setOpenImportOrder}
          message="Deseja importar as ordens de pagamento do OMIE?"
          subMessage="Somente serão importadas as ordens faltantes."
          buttonText="Importar"
          actionAcceptButton={handleImportOrder}
        />
      )}
    </Box>
  )
}

PaymentOrdersCard.propTypes = {
  paymentContract: PropTypes.object,
  reload: PropTypes.func,
}

export default PaymentOrdersCard
