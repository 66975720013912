import { Grid } from '@material-ui/core'

import { Card, Label } from 'components'

import helpers from 'helpers'

const Information = ({ paymentContract }) => {
  const address = paymentContract?.preRegistration?.address
  return (
    <Card title="Dados do cliente">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Label
            title="Nome"
            description={paymentContract?.preRegistration?.name}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Label
            title="E-mail"
            description={paymentContract?.preRegistration?.email}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Label
            title="CNPJ/CPF"
            description={helpers.formatters.cnpjAndCpfMask(
              paymentContract?.preRegistration?.document || '',
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={3}>
          <Label
            title="Endereço"
            description={`${address?.street}, ${address?.number}`}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Label title="Bairro" description={address?.neighborhood} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Label
            title="Município, UF - País"
            description={`${address?.city}, ${address?.state} - ${address?.country}`}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Label
            title="CEP"
            description={helpers.formatters.cep(address?.postalCode)}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default Information
