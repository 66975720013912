import { Filters } from 'components'

import constants from 'constants/index'

const ChurnFilter = ({ filter, churns }) => {
  const responsibles = Array.from(
    new Map(
      churns?.map((churn) => [churn.responsible.id, churn.responsible]),
    ).values(),
  )

  return (
    <Filters filter={filter}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Nome da empresa" name="companyName" />
      <input textfieldinput="true" label="Documento" name="companyDocument" />
      <select
        autocompleteselectinput="true"
        compareByName
        name="responsibleId"
        label="Colaborador responsável"
        optionvalues={responsibles || []}
      />
      <input textfieldinput="true" label="Ticket" name="ticketId" />
      <input
        textfieldinput="true"
        label="Data da solicitação"
        name="ticketDate"
      />
      <select textfieldinput="true" label="Status" name="status">
        <option value=""></option>
        <option value={constants.churn.INITIATED_STATUS}>Iniciado</option>
        <option value={constants.churn.MEETING_STATUS}>Reunião agendada</option>
        <option value={constants.churn.CONVERTED_STATUS}>Convertido</option>
        <option value={constants.churn.CHURN_STATUS}>Churn</option>
      </select>
    </Filters>
  )
}

export default ChurnFilter
