import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Box, Button } from '@material-ui/core'

import {
  AddressForm,
  CopyCompanySelection,
  EconomicActivityForm,
  LogoInput,
  SegmentationForm,
  ResponsibleForm,
  SecondaryEconomicActivityTable,
  InviteAdvisor,
  DemoPartner,
  PermissionForm,
} from '..'
import { MainForm } from './components'
import { ChangeResponsibleDialog } from '../ResponsibleForm/components'
import { SelectSecondaryEconomicActivityDialog } from '../SecondaryEconomicActivityTable/components'

import { useSnackbar } from 'hooks'

import schema from './schema'
import useStyles from './styles'
import helpers from 'helpers'
import * as service from 'service'
import { routes } from 'Routes'
import constants from 'constants/index'

const JuridicOrPersonalForm = ({
  disableCertFields,
  setLoading,
  editionPermitted,
  company,
  refresh,
  loadSupplierData,
  typeDefault,
}) => {
  const [changeResponsibleDialog, setChangeResponsibleDialog] = useState(false)
  const [
    selectSecondaryEconomicActivityDialog,
    setSelectSecondaryEconomicActivityDialog,
  ] = useState(false)
  const [logoFile, setLogoFile] = useState(null)
  const [outsourcedDpoData, setOutsourcedDpoData] = useState(null)
  const [observerData, setObserverData] = useState(null)
  const [fantasyDpoData, setFantasyDpoData] = useState(null)
  const [oldResponsibleId, setOldResponsibleId] = useState(
    company?.responsibleUserCompanyId,
  )
  const [phoneSelected, setPhoneSelected] = useState(
    company?.responsableUser?.phone || '',
  )
  const [checkedBlockUpdate, setCheckedBlockUpdate] = useState(
    disableCertFields ? company?.blockUpdate : false,
  )

  const phoneIsEmpty = isEmpty(phoneSelected)
  const isEasy = company?.companyType === 'DPO Easy'

  const history = useHistory()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const {
    control,
    handleSubmit,
    errors,
    reset,
    register,
    getValues,
    setValue,
    watch,
  } = useForm({
    validationSchema: schema(phoneIsEmpty, isEasy),
    defaultValues: {
      name: company?.name || '',
      companyName: company?.companyName || '',
      description: company?.description || '',
      kind: company?.kind || typeDefault,
      document: company?.cnpj || company?.cpf || '',
      municipalRegistration: company?.municipalRegistration || ' ',
      stateRegistration: company?.stateRegistration || '',
      siteUrl: helpers.formatters.website(company?.siteUrl) || '',
      email: company?.email || '',
      phone: helpers.formatters.phone(company?.phone) || '',
      companyTemplateId: company?.templateCompany || {
        id: 0,
        name: '',
      },
      statusId: company?.statusId || constants.companies.STATUSES.TEST,
      blockUpdate: company?.blockUpdate || false,
      acceptProcessFragility: company?.acceptProcessFragility || false,
      notifyDataProcessChanges: company?.notifyDataProcessChanges || false,
      groupId: company?.group || { id: 0, name: '' },
      subgroupId: company?.subgroup || { id: 0, name: '' },
      segmentId: company?.segment || { id: 0, name: '' },
      companySizeId: company?.companySize || { id: 0, name: '' },
      street: company?.address?.street || '',
      number: company?.address?.number || '',
      neighborhood: company?.address?.neighborhood || '',
      complement: company?.address?.complement || '',
      postalCode: helpers.formatters.cep(company?.address?.postalCode) || '',
      city: company?.address?.city || '',
      country: company?.address?.country || 'Brasil',
      state: company?.address?.state || '',
      responsibleName: company?.responsableUser?.name || '',
      responsibleEmail: company?.responsableUser?.email || '',
      responsiblePhone: company?.responsableUser?.phone || '',
      responsibleUserId: company?.responsableUser
        ? {
            id: company?.responsableUser?.id,
            name: company?.responsableUser?.name,
            ...(phoneIsEmpty && {
              phone: company?.responsableUser?.phone,
            }),
          }
        : { id: 0, name: '' },
      economicActivityId: company?.mainEconomicActivity || {
        id: 0,
        name: '',
      },
    },
  })

  const isJuridic = watch('kind') === constants.companies.KINDS.COMPANY
  const isReal = company?.companyType === constants.companies.KINDS.REAL
  const isTest = company?.companyType === constants.companies.KINDS.TEST
  const isRealOrTest = isReal || isTest
  const fantasyDpo = company?.outsourced?.fantasyDpo

  const onSubmit = (data) => {
    setLoading(true)

    if (company && !helpers.companies.isSupplier(company)) {
      handleEdit(data)
    } else {
      handleCreate(data)
    }
  }

  const handleCreate = async (data) => {
    let params = isJuridic
      ? helpers.companies.formatJuridicParamsToApi(data)
      : helpers.companies.formatPersonalParamsToApi(data)

    try {
      let response = await service.dponet.companies.create({
        company: params,
      })

      if (outsourcedDpoData) {
        const newData = {
          email: data?.email,
          fantasyDpo: data?.email,
        }

        await service.dponet.outsourcedDpo.create({
          data: newData,
          companyId: response?.data?.company?.id,
        })
      }

      if (observerData) {
        await service.dponet.observerInvite.create({
          data: observerData,
          companyId: response?.data?.company?.id,
        })
      }

      if (logoFile) {
        await service.dponet.companies.addLogo({
          companyId: response?.data?.company?.id,
          logo: logoFile,
        })
      }

      snackbar.open({
        message: 'Empresa criada com sucesso!',
        variant: 'success',
      })
      setLoading(false)

      refresh()

      history.push(routes.companies.all)
    } catch (error) {
      handleError(error, data)
      setLoading(false)
    }
  }

  const handleEdit = async (data) => {
    let params = isJuridic
      ? helpers.companies.formatJuridicParamsToApi(data)
      : helpers.companies.formatPersonalParamsToApi(data)

    try {
      let response = await service.dponet.companies.put({
        company: params,
        companyId: company?.id,
      })

      if (logoFile) {
        await service.dponet.companies.addLogo({
          companyId: response?.data?.company?.id,
          logo: logoFile,
        })
      }

      snackbar.open({
        message: 'Empresa editada com sucesso!',
        variant: 'success',
      })
      setLoading(false)

      refresh()

      history.push(isEasy ? routes.companies.easy : routes.companies.all)
    } catch (error) {
      handleError(error, data)
      setLoading(false)
    }
  }

  const handleError = (error, data) => {
    setLoading(false)
    reset(data)
    snackbar.open({
      message: helpers.formatters.errorMessage(
        error?.response?.data?.error,
        false,
      ),
      variant: 'error',
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.fullWidth}>
        <Box>
          <MainForm
            control={control}
            errors={errors}
            watch={watch}
            editionPermitted={editionPermitted}
            loadSupplierData={loadSupplierData}
            setValue={setValue}
            isEditChecked={checkedBlockUpdate}
            setChecked={setCheckedBlockUpdate}
            disableCertFields={disableCertFields}
            isJuridic={isJuridic}
            company={company}
          />
        </Box>
        <Box mt={4}>
          <PermissionForm
            register={register}
            control={control}
            disableCertFields={disableCertFields}
            company={company}
            checked={checkedBlockUpdate}
            setChecked={setCheckedBlockUpdate}
          />
        </Box>
        <Box mt={4}>
          <AddressForm
            control={control}
            errors={errors}
            editionPermitted={editionPermitted}
          />
        </Box>
        <Box mt={4}>
          <ResponsibleForm
            control={control}
            errors={errors}
            disableCertFields={disableCertFields}
            setChangeResponsibleDialog={setChangeResponsibleDialog}
            setLoading={setLoading}
            register={register}
            company={company}
            editionPermitted={editionPermitted}
            setPhoneSelected={setPhoneSelected}
            phoneSelected={phoneSelected}
            isEasy={isEasy}
            isReal={isReal}
          />
        </Box>
        <Box mt={4}>
          <CopyCompanySelection
            errors={errors}
            control={control}
            reset={reset}
            getValues={getValues}
            setLoading={setLoading}
            disableCertFields={disableCertFields}
            company={company}
          />
        </Box>

        <Box mt={4}>
          <LogoInput
            logo={company?.logo?.url}
            setLogoFile={setLogoFile}
            editionPermitted={editionPermitted}
          />
        </Box>
        <Box mt={4}>
          {isRealOrTest && (
            <>
              <InviteAdvisor
                refresh={refresh}
                company={company}
                invite={company?.outsourced}
                dpoType={constants.inviteAdvisor.DPO_TYPE.RESPONSIBLE}
                isEdit={disableCertFields}
                advisorData={outsourcedDpoData}
                setAdvisorData={setOutsourcedDpoData}
              />
              {(company?.outsourced || outsourcedDpoData) && (
                <InviteAdvisor
                  refresh={refresh}
                  company={company}
                  invite={fantasyDpo}
                  dpoType={constants.inviteAdvisor.DPO_TYPE.FANTASY}
                  isEdit={disableCertFields}
                  advisorData={fantasyDpoData}
                  setAdvisorData={setFantasyDpoData}
                />
              )}
              <InviteAdvisor
                refresh={refresh}
                company={company}
                dpoType={constants.inviteAdvisor.DPO_TYPE.OBSERVER}
                invite={company?.observer}
                isEdit={disableCertFields}
                advisorData={observerData}
                setAdvisorData={setObserverData}
              />
            </>
          )}
        </Box>
        <Box mt={4}>
          <SegmentationForm
            control={control}
            errors={errors}
            setValue={setValue}
            setLoading={setLoading}
            group={company?.group}
            subgroup={company?.subgroup}
            editionPermitted={editionPermitted}
          />
        </Box>
        <Box mt={4}>
          <DemoPartner
            refresh={refresh}
            demoPartner={company?.demoPartner}
            companyId={company?.id}
          />
        </Box>
        <Box mt={4}>
          <EconomicActivityForm
            control={control}
            errors={errors}
            disableCertFields={disableCertFields}
            editionPermitted={editionPermitted}
            company={company}
          />
        </Box>
        {company && (
          <Box mt={4}>
            <SecondaryEconomicActivityTable
              companyId={company?.id}
              control={control}
              errors={errors}
              setSelectSecondaryEconomicActivityDialog={
                setSelectSecondaryEconomicActivityDialog
              }
              editionPermitted={editionPermitted}
            />
          </Box>
        )}
        {editionPermitted && (
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary">
              {company ? 'Salvar' : 'Salvar e criar empresa'}
            </Button>
          </Box>
        )}
      </form>
      {company && editionPermitted && (
        <>
          <SelectSecondaryEconomicActivityDialog
            open={selectSecondaryEconomicActivityDialog}
            setOpen={setSelectSecondaryEconomicActivityDialog}
            company={company}
          />
          {oldResponsibleId && (
            <ChangeResponsibleDialog
              open={changeResponsibleDialog}
              setOpen={setChangeResponsibleDialog}
              companyId={company?.id}
              oldResponsibleId={oldResponsibleId}
              setOldResponsibleId={setOldResponsibleId}
              setValue={setValue}
              refresh={refresh}
            />
          )}
        </>
      )}
    </>
  )
}

JuridicOrPersonalForm.propTypes = {
  disableCertFields: PropTypes.bool,
  setLoading: PropTypes.func,
  editionPermitted: PropTypes.bool,
  company: PropTypes.object,
  loadSupplierData: PropTypes.func,
}

JuridicOrPersonalForm.defaultProps = {
  setLoading: () => {},
  loadSupplierData: () => {},
}

export default JuridicOrPersonalForm
