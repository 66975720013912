import api from 'service/api'

const dponetAPI = api.create('dponet')

const update = async ({ demoPartnerId, demoPartner }) => {
  return await dponetAPI.put(`/demo_partners/${demoPartnerId}`, { demoPartner })
}

const create = async ({ demoPartner }) => {
  return await dponetAPI.post('/demo_partners', {
    demoPartner,
  })
}

const demoPartner = {
  update,
  create,
}

export default demoPartner
