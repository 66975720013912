import { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { SupplierDestroyDialog, SupplierRow } from './components'

const SuppliersTable = ({ suppliers, refresh }) => {
  const [openDestroyDialog, setOpenDestroyDialog] = useState(false)
  const [selectedSupplier, setSelectedSupplier] = useState(null)

  const handleOpenDestroyDialog = (supplier) => {
    setOpenDestroyDialog(true)
    setSelectedSupplier(supplier)
  }

  const handleCloseDestroyDialog = () => {
    setOpenDestroyDialog(false)
    setSelectedSupplier(null)
  }

  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhuma empresa encontrada" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="10%">ID</TableCell>
              <TableCell width="20%" align="left">
                Empresa contratante
              </TableCell>
              <TableCell width="20%" align="left">
                Fornecedor
              </TableCell>
              <TableCell width="15%" align="left">
                Segmento
              </TableCell>
              <TableCell width="15%" align="left">
                Status do vinculo
              </TableCell>
              <TableCell width="15%" align="left">
                Conformidade(%)
              </TableCell>
              <TableCell width="5%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliers?.map((supplier) => (
              <SupplierRow
                supplier={supplier}
                key={supplier?.id}
                hover
                handleDestroy={handleOpenDestroyDialog}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>

      <SupplierDestroyDialog
        open={openDestroyDialog}
        onClose={handleCloseDestroyDialog}
        companySupplier={selectedSupplier}
        refresh={refresh}
      />
    </Box>
  )
}

SuppliersTable.propTypes = {
  suppliers: PropTypes.array,
}

export default SuppliersTable
