import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  customTab: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row !important',
      gap: '8px',
    },
  },
}))

export default styles
