import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { Button, Chip } from '@material-ui/core'
import { reverse } from 'named-urls'

import { MenuButton } from 'components'
import { TableRow, TableCell } from 'components/Table'

import { routes } from 'Routes'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'

const SuplierRow = ({ supplier, handleDestroy, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()

  const navigateToShow = () => {
    history.push(
      reverse(routes.suppliers.show, {
        companySupplierId: supplier?.id,
      }),
    )
  }

  return (
    <TableRow {...rest}>
      <TableCell>{supplier?.id}</TableCell>
      <TableCell>{supplier?.company?.name}</TableCell>
      <TableCell>{supplier?.supplier?.name || '-'}</TableCell>
      <TableCell>{supplier?.supplier?.segment?.name}</TableCell>
      <TableCell disableTooltip>
        <Chip
          size="small"
          className={clsx(
            classes.chip,
            helpers.companySuppliers.chipStatusColor(supplier?.status, classes),
          )}
          label={constants.companySuppliers.STATUSES_LABELS[supplier?.status]}
        />
      </TableCell>
      <TableCell>
        {supplier?.supplier?.companyCompliance?.toFixed(0) || 0}%
      </TableCell>
      <TableCell disableTooltip align="right">
        <MenuButton>
          <Button fullWidth size="small" onClick={navigateToShow}>
            Visualizar
          </Button>
          <Button
            fullWidth
            size="small"
            onClick={() => handleDestroy(supplier)}
          >
            Deletar
          </Button>
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

export default SuplierRow
