import { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import {
  BaseDialog,
  Permitted,
  ConfirmationDialog,
  ButtonLogs,
} from 'components'
import {
  HistorySolicitation,
  ConfirmationPayment,
  ConfirmationLoss,
  Nfe,
  Resend,
  PaymentStep,
  Reckoning,
  ConfirmationCancel,
} from './components'

import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'
import { routes } from 'Routes'

const PaymentOrderButtonAction = ({
  paymentOrder,
  refresh = () => {},
  setLoading,
}) => {
  const snackbar = useSnackbar()
  const history = useHistory()

  const [openConfirmatedPaidDialog, setOpenConfirmatedPaidDialog] =
    useState(false)
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false)
  const [openConfirmatedLoss, setOpenConfirmatedLoss] = useState(false)

  const [openNfe, setOpenNfe] = useState(null)
  const [openResend, setOpenResend] = useState(false)
  const [openStep, setOpenStep] = useState(false)
  const [openReckoning, setReckoning] = useState(false)
  const [openDestroy, setOpenDestroy] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)

  const { PAID_STATUS } = constants.paymentOrder.STATUSES

  const paymentOrderNotPaid = paymentOrder?.status !== PAID_STATUS

  const handleOpenDestroy = () => setOpenDestroy(true)
  const handleOpenCancel = () => setOpenCancel(true)
  const handleOpenResend = () => setOpenResend(true)

  const handleOpenStep = () => setOpenStep(true)
  const handleOpenReckoning = () => setReckoning(true)
  const handleOpenNfe = () => setOpenNfe(true)

  const handleView = () =>
    history.push(
      reverse(routes.paymentOrders.show, {
        paymentOrderId: paymentOrder?.id,
      }),
    )

  const handleBillet = async () => {
    setLoading(true)

    try {
      const response = await service.dponet.paymentOrders.billet({
        paymentOrderId: paymentOrder?.id,
      })

      window.open(response?.data, '_blank')

      snackbar.open({
        message: 'Boleto disponível para baixar!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao baixar o boleto, tente novamente mais tarde!',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDestroy = async () => {
    setLoading(true)
    try {
      await service.dponet.paymentOrders.destroy({
        paymentOrderId: paymentOrder?.id,
      })

      snackbar.open({
        message: 'Cobrança excluída com sucesso!',
        variant: 'success',
      })

      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao excluir a cobrança, tente novamente mais tarde!',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setOpenDestroy(false)
    }
  }

  const handleResend = async () => {
    setLoading(true)
    try {
      await service.dponet.paymentOrders.resend({
        paymentOrderId: paymentOrder?.id,
      })

      snackbar.open({
        message: 'Reenvio de cobrança realizada com sucesso!',
        variant: 'success',
      })

      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao reenviar cobrança, tente novamente mais tarde!',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setOpenResend(false)
    }
  }

  return (
    <>
      <Permitted tag={constants.permissions.PAYMENT_ORDERS.MANAGE_PAYMENT}>
        <Button fullWidth size="small" onClick={handleView}>
          Visualizar
        </Button>
        <Button fullWidth size="small" onClick={handleBillet}>
          Baixar Boleto
        </Button>
        <Button fullWidth size="small" onClick={handleOpenNfe}>
          Nota fiscal
        </Button>
        {paymentOrderNotPaid && (
          <Button
            fullWidth
            size="small"
            onClick={() => {
              setOpenConfirmatedPaidDialog(true)
            }}
          >
            Confirmar pagamento
          </Button>
        )}
        <Button
          fullWidth
          size="small"
          onClick={() => {
            setOpenConfirmatedLoss(true)
          }}
        >
          Confirmar perda
        </Button>
        {!isEmpty(paymentOrder?.reckoningData) && (
          <Button fullWidth size="small" onClick={handleOpenReckoning}>
            Lançamento de conta a receber
          </Button>
        )}
        <Button fullWidth size="small" onClick={handleOpenStep}>
          Etapas da cobrança
        </Button>
        <Button fullWidth size="small" onClick={handleOpenCancel}>
          Solicitar o cancelamento
        </Button>
        <Button fullWidth size="small" onClick={handleOpenResend}>
          Reenviar cobrança (2° via)
        </Button>
        <Button
          fullWidth
          size="small"
          onClick={() => setOpenHistoryDialog(true)}
        >
          Histórico de solicitação
        </Button>
        <ButtonLogs entityId={paymentOrder?.id} routes="payment_orders" />
        <Button fullWidth size="small" onClick={handleOpenDestroy}>
          Excluir cobrança
        </Button>
      </Permitted>
      {openConfirmatedPaidDialog && (
        <ConfirmationPayment
          open={openConfirmatedPaidDialog}
          setOpen={setOpenConfirmatedPaidDialog}
          refresh={refresh}
          setLoading={setLoading}
          paymentOrder={paymentOrder}
        />
      )}
      {openConfirmatedLoss && (
        <ConfirmationLoss
          paymentOrderId={paymentOrder?.id}
          open={openConfirmatedLoss}
          setOpen={setOpenConfirmatedLoss}
          refresh={refresh}
        />
      )}
      {openHistoryDialog && (
        <BaseDialog
          open={openHistoryDialog}
          setOpen={setOpenHistoryDialog}
          title="Histórico de solicitação"
          closeButtonText="Cancelar"
          dialogSize="sm"
          noMargin
        >
          <HistorySolicitation paymentOrderId={paymentOrder?.id} />
        </BaseDialog>
      )}
      {openNfe && (
        <Nfe
          setOpen={setOpenNfe}
          open={openNfe}
          dataNfe={paymentOrder?.dataNfe}
          paymentOrderId={paymentOrder?.id}
          refresh={refresh}
          isOrderService={paymentOrder?.codeOrderServiceOmie}
        />
      )}
      {openResend && (
        <ConfirmationDialog
          open={openResend}
          setOpen={setOpenResend}
          buttonText="Confirmar"
          message="Deseja realmente reenviar a cobrança?"
          subMessage="Será enviado um novo e-mail com a cobrança para o cliente. <br /><br />
                      Caso queira gerar uma nova cobrança, clique em 
                      <strong>'NOVA COBRANÇA'</strong> na listagem de cobranças que fica na tela de contrato."
          actionAcceptButton={handleResend}
        />
      )}
      {openStep && (
        <PaymentStep
          open={openStep}
          setOpen={setOpenStep}
          paymentOrderId={paymentOrder?.id}
        />
      )}
      {openReckoning && (
        <Reckoning
          open={openReckoning}
          setOpen={setReckoning}
          reckoning={paymentOrder?.reckoningData}
          refresh={refresh}
        />
      )}
      {openDestroy && (
        <ConfirmationDialog
          open={openDestroy}
          setOpen={setOpenDestroy}
          buttonText="Confirmar"
          message="Deseja realmente excluir a cobrança?"
          subMessage="Essa ação não é definitiva, a cobrança será marcada como excluída e poderá ser recuperada a qualquer momento."
          actionAcceptButton={handleDestroy}
        />
      )}
      {openCancel && (
        <ConfirmationCancel
          open={openCancel}
          setOpen={setOpenCancel}
          refresh={refresh}
          paymentOrder={paymentOrder}
          setLoading={setLoading}
        />
      )}
    </>
  )
}

PaymentOrderButtonAction.propTypes = {
  paymentOrder: PropTypes.object,
  refresh: PropTypes.func,
  isRelatedCompany: PropTypes.bool,
}

export default PaymentOrderButtonAction
