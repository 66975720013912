import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Typography, Box, Button } from '@material-ui/core'
import DOMPurify from 'dompurify'

import { LoadingButton } from 'components'

const ConfirmationDialog = ({
  open,
  setOpen,
  message,
  subMessage,
  alert,
  buttonText = 'concordo',
  actionAcceptButton,
  cancelButtonText = 'cancelar',
  ...rest
}) => {
  const [loading, setLoading] = useState(false)

  const handleAccept = async () => {
    setLoading(true)
    actionAcceptButton()
  }

  const handleClose = (_, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (loading && !open) {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        px={4}
        pt={6}
        pb={4}
        gridGap={6}
      >
        <Typography variant="h4" align="center">
          {message}
        </Typography>
        {subMessage && (
          <Typography
            variant="h6"
            align="center"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subMessage) }}
          />
        )}
        {alert && (
          <Typography variant="h6" align="center" color="error">
            {alert}
          </Typography>
        )}
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={2}
      >
        <Box mr={2}>
          <Button
            disabled={loading}
            onClick={handleClose}
            variant="outlined"
            disableElevation
          >
            {cancelButtonText}
          </Button>
        </Box>
        <LoadingButton
          loading={loading}
          text={buttonText}
          alert={!!alert}
          autoFocus
          onClick={handleAccept}
          disableElevation
          {...rest}
        />
      </Box>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  message: PropTypes.string,
  subMessage: PropTypes.string,
  alert: PropTypes.string,
  buttonText: PropTypes.string,
  actionAcceptButton: PropTypes.func,
  cancelButtonText: PropTypes.string,
}

export default ConfirmationDialog
