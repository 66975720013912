import { useState } from 'react'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { Plus as PlusIcon } from 'react-feather'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'
import { ChurnCreateModal, ChurnsTable, ChurnFilter } from './components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import { usePagination } from 'hooks'

import constants from 'constants/index'
import * as service from 'service'
import useStyles from './styles'
import theme from 'theme'

const ChurnsMain = () => {
  const filter = useFilter()
  const classes = useStyles()

  const [openModal, setOpenModal] = useState(false)

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const handleOpenModal = () => setOpenModal((prevOpen) => !prevOpen)

  const { response, isLoading } = useFetch(
    service.dponet.churns.get,
    {
      ...{ ...filter.filters },
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  return (
    <Page title="Listagem de solicitações de churns">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={15}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Churns"
          title="Listagem"
          subtitle="Solicitações de churns"
        >
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Card>
          <Grid container spacing={2} alignItems="center" component={Box} p={2}>
            <Grid item xs={12} sm={8} className={classes.container}>
              <Typography variant="h5">Solicitações de Churns</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box justifyContent="flex-end" className={classes.container}>
                <Permitted tag={constants.permissions.CHURNS.MANAGE}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<PlusIcon />}
                    onClick={handleOpenModal}
                  >
                    Criar
                  </Button>
                </Permitted>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <ChurnsTable churns={response?.data?.churns || []} />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
      </Container>
      {openModal && (
        <ChurnCreateModal open={openModal} setOpen={setOpenModal} />
      )}

      <ChurnFilter filter={filter} churns={response?.data?.churns} />
    </Page>
  )
}

export default ChurnsMain
