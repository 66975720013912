import constants from 'constants/index'

const { STATUSES, CLIENT_TYPE } = constants.preRegistration

const mountReal = (data, parentContractId = null) => {
  const {
    country,
    state,
    city,
    neighborhood,
    street,
    number,
    postalCode,
    complement,
    sellerId,
    fantasyName,
    document,
    email,
    phone,
    planId,
    name,
    kind,
    type,
    responsibleEmail,
    responsibleName,
    responsiblePhone,
    typeClient,
    typeService,
    vertical,
    companyTemplate,
  } = data

  const responsibleUser = {
    email: responsibleEmail,
    name: responsibleName,
    phone: responsiblePhone,
  }

  return {
    name: name || fantasyName,
    fantasyName,
    document,
    email,
    phone,
    planId,
    sellerId,
    responsibleUser,
    kind,
    address: {
      postalCode,
      country,
      state,
      city,
      neighborhood,
      street,
      number,
      complement,
    },
    type,
    parentContractId,
    typeClient,
    typeService,
    vertical,
    companyTemplateId: companyTemplate?.id,
    status:
      typeClient === CLIENT_TYPE
        ? STATUSES.INITIATED_STATUS
        : STATUSES.FINALIZED_STATUS,
  }
}

export default mountReal
