import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ companySupplierId = '', ...params }) => {
  return await dponetAPI.get(`/company_suppliers/${companySupplierId}`, {
    params,
  })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/company_suppliers/', data)
}

const logs = async ({ companySupplierId = '', ...params }) => {
  return await dponetAPI.get(`/company_suppliers/${companySupplierId}/logs`, {
    params,
  })
}

const destroy = async ({ companySupplierId }) => {
  return await dponetAPI.delete(`/company_suppliers/${companySupplierId}`)
}

const supplierQuestionnaires = async ({ companySupplierId }) => {
  return await dponetAPI.get(
    `/company_suppliers/${companySupplierId}/supplier_questionnaires`,
  )
}

const companySuppliers = {
  get,
  create,
  destroy,
  logs,
  supplierQuestionnaires,
}

export default companySuppliers
