import { Box, Divider, Typography } from '@material-ui/core'

import clsx from 'clsx'

import constants from 'constants/index'

import useStyles from './styles'

const PaymentMethodTabs = ({ setMethod, method, clearErrors }) => {
  const classes = useStyles()
  const options = constants.preRegistration.OPTIONS_METHOD_PAYMENT
  const icons = constants.preRegistration.PAYMENT_METHOD_ICONS

  return (
    <Box width="100%" mt={4} mb={0.5}>
      <Box display="flex" alignItems="center" justifyContent="center">
        {options.map((option) => (
          <Box
            className={clsx(classes.tab, {
              [classes.tabActive]: method === option.id,
            })}
            onClick={() => {
              setMethod(option.id)
              clearErrors()
            }}
          >
            {option.id === 'pix' ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="40"
                width="40"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#233154"
                  d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.8C231.1-7.6 280.3-7.6 310.6 22.8L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.8L22.8 310.6C-7.6 280.3-7.6 231.1 22.8 200.8L80.8 142.7H112.6z"
                />
              </svg>
            ) : (
              icons[option.id]
            )}

            <Typography variant="h4" className={classes.cardText}>
              {option.name}
            </Typography>
          </Box>
        ))}
      </Box>
      <Divider />
    </Box>
  )
}

export default PaymentMethodTabs
