import PropTypes from 'prop-types'

import { Grid, TextField } from '@material-ui/core'

import { Controller } from 'react-hook-form'

import { Card, RadioTypeCompany } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'
import { useEffect } from 'react'

const CompanyData = ({
  control,
  errors,
  watch,
  isTrialCompany,
  setValue,
  preRegistration,
  isEdit,
  companySupplierIsJuridic,
  isSupplier,
  companySupplier,
}) => {
  const checked = watch('kind')

  useEffect(() => {
    if (isSupplier) {
      setValue('document', companySupplier?.document)
    } else if (checked !== preRegistration?.kind && isEdit) {
      setValue('document', '')
    } else {
      if (checked === constants.companies.KIND_COMPANY) {
        return setValue(
          'document',
          helpers.formatters.cnpjInput(preRegistration?.document),
        )
      }
      setValue(
        'document',
        helpers.formatters.cpf(preRegistration?.document || ''),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked])

  const isJuridic =
    checked === constants.companies.KIND_COMPANY || companySupplierIsJuridic

  return (
    <Card title="Preencha os dados da empresa">
      <Grid container spacing={1}>
        <RadioTypeCompany
          control={control}
          watch={watch}
          disabled={isSupplier}
        />
        <Grid item xs={isJuridic ? 6 : 12}>
          <Controller
            control={control}
            name="fantasyName"
            mode="onChange"
            as={
              <TextField
                fullWidth
                error={!!errors.fantasyName}
                helperText={errors?.fantasyName?.message}
                label="Nome fantasia da empresa"
                inputProps={{ maxLength: 60 }}
              />
            }
          />
        </Grid>
        {isJuridic && (
          <Grid item xs={6}>
            <Controller
              control={control}
              name="name"
              mode="onChange"
              as={
                <TextField
                  fullWidth
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  label="Razão social da empresa"
                  inputProps={{ maxLength: 60 }}
                />
              }
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Controller
            control={control}
            name="document"
            mode="onChange"
            onChange={([event]) => {
              const document = helpers.formatters.onlyNumbersString(
                event.target.value,
              )

              if (isJuridic) {
                return helpers.formatters.cnpjInput(document)
              }

              return helpers.formatters.cpf(document)
            }}
            as={
              <TextField
                fullWidth
                error={!!errors.document}
                helperText={errors?.document?.message}
                disabled={isSupplier}
                label={isJuridic ? 'CNPJ' : 'CPF'}
              />
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.phone}
                helperText={errors?.phone?.message}
                label="Telefone da empresa"
              />
            }
            onChange={([event]) => {
              return helpers.formatters.phone(event.target.value)
            }}
            control={control}
            name="phone"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.email}
                helperText={errors?.email?.message}
                label="E-mail da empresa"
              />
            }
            control={control}
            name="email"
            mode="onBlur"
          />
        </Grid>
      </Grid>
    </Card>
  )
}

CompanyData.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func,
  errors: PropTypes.object.isRequired,
  isTrialCompany: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  preRegistration: PropTypes.object,
  isEdit: PropTypes.bool,
}

export default CompanyData
