import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { Label } from 'components'
import useStyles from '../../styles'
import PropTypes from 'prop-types'

import helpers from 'helpers'
import constants from 'constants/index'

const DemoPartnerCard = ({ demoPartner = {} }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Dados de gratuidade" />
      <Divider />
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Label titleVariant="body1" title="Tipo">
              <Typography variant="body2">
                {constants.preRegistration.KIND_TRIAL[demoPartner?.kind]}
              </Typography>
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label titleVariant="body1" title="Vencimento">
              <Typography variant="body2">
                {helpers.formatters.date(demoPartner?.endDate)}
              </Typography>
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

DemoPartnerCard.propTypes = {
  demoParner: PropTypes.object,
}

export default DemoPartnerCard
