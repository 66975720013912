import { useState } from 'react'
import { Button, Box, Grid } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { Repeat as RepeatIcon } from 'react-feather'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'

import useSnackbar from 'hooks/useSnackbar'

import {
  MenuButton,
  LabelIconStart,
  BaseDialog,
  LoadingFeedback,
  Select,
} from 'components'

import * as service from 'service'
import helpers from 'helpers'
import schema from '../schema'
import constants from 'constants/index'

const DemoPartnerMenuItems = ({
  refresh,
  editionMode,
  demoPartner,
  companyId,
}) => {
  const [open, setOpen] = useState(false)
  const handleActionDialog = () => setOpen((open) => !open)
  const [isLoading, setIsloading] = useState(false)
  const snackbar = useSnackbar()

  const { control, errors, triggerValidation, getValues } = useForm({
    validationSchema: schema,
    defaultValues: {
      endDate: moment(demoPartner?.endDate) || moment(),
      kind: demoPartner?.kind || constants.preRegistration.KIND_TRIAL_LIMITED,
    },
  })
  const onSubmit = async (data) => {
    setIsloading(true)
    const { endDate, kind } = data
    try {
      const action = demoPartner?.id ? 'update' : 'create'

      await service.dponet.demoPartner[action]({
        demoPartnerId: demoPartner?.id,
        demoPartner: {
          endDate: new Date(endDate),
          kind: kind,
          companyId: companyId,
        },
      })
      snackbar.open({
        message: `Gratuidade ${
          demoPartner?.id ? 'atualizado' : 'criado'
        } com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          `Falha ao ${demoPartner?.id ? 'atualizar' : 'criar'} gratuidade!`,
        variant: 'error',
      })
    }
    setIsloading(false)
    setOpen(false)
    refresh()
  }

  const handle = async () => {
    if (await triggerValidation()) {
      onSubmit(getValues())
    }
  }

  return (
    <>
      {editionMode && (
        <Box display="flex" alignItems="center">
          <MenuButton>
            <Button color="secondary" fullWidth onClick={handleActionDialog}>
              <LabelIconStart
                icon={<RepeatIcon size={20} />}
                label={`${
                  demoPartner?.id ? 'Alterar' : 'Adicionar'
                } gratuidade`}
              />
            </Button>
          </MenuButton>
        </Box>
      )}
      <LoadingFeedback open={isLoading} />
      <BaseDialog
        open={open}
        setOpen={setOpen}
        title={`${demoPartner?.id ? 'Alterar' : 'Adicionar'} gratuidade`}
        closeButtonText="Cancelar"
        actionButtonText="Salvar"
        actionButton={handle}
        actionButtonType="button"
      >
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                as={
                  <DatePicker
                    label="Vencimento"
                    color="primary"
                    format="DD/MM/yyyy"
                    variant="outlined"
                    error={!!errors?.endDate}
                    helperText={errors?.endDate?.message}
                    fullWidth
                  />
                }
                control={control}
                name="endDate"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="kind"
                mode="onBlur"
                as={
                  <Select
                    label="Tipo"
                    items={constants.preRegistration.TRIAL_TYPES_OPTIONS || []}
                    error={!!errors?.kind}
                    helperText={errors?.kind?.message}
                  />
                }
              />
            </Grid>
          </Grid>
        </form>
      </BaseDialog>
    </>
  )
}

export default DemoPartnerMenuItems
